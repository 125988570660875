import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
//import HomepageLight from "./pages/HomepageLight";
// import HomepageDark from "./pages/HomepageDark";
// import Homepage2 from "./pages/Homepage2";
// import Homepage2Light from "./pages/Homepage2Light";
// import Homepage2Dark from "./pages/Homepage2Dark";
// import Homepage3 from "./pages/Homepage3";
// import Homepage3Light from "./pages/Homepage3Light";
// import Homepage3Dark from "./pages/Homepage3Dark";
// import Bloglist from "./pages/Bloglist";
// import BlogDetails from "./pages/BlogDetails";
import "./App.scss";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Homepage />} />
        {/*<Route path="/dark" exact>
          <HomepageDark />
        </Route>
        <Route path="/light" exact>
           <HomepageLight />
        </Route>
        <Route path="/index-2" exact>
          <Homepage2 />
        </Route>
        <Route path="/index-2-light" exact>
          <Homepage2Light />
        </Route>
        <Route path="/index-2-dark" exact>
          <Homepage2Dark />
        </Route>
        <Route path="/index-3" exact>
          <Homepage3 />
        </Route>
        <Route path="/index-3-light" exact>
          <Homepage3Light />
        </Route>
        <Route path="/index-3-dark" exact>
          <Homepage3Dark />
        </Route>
        <Route path="/blogs" component={Bloglist} exact />
        <Route path="/blogs/blog-details/:id/:title" component={BlogDetails} /> */}
      </Routes>
    </Router>
  );
}

export default App;
