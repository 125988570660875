import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';

function Pagetitle({ title }) {
  return (
    <>
      <AnimationOnScroll
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <h2 className="section-title">{title}</h2>
      </AnimationOnScroll>
      <div className="spacer" data-height="60"></div>
    </>
  );
}

export default Pagetitle;
